import { OBJECT_EDIT_TYPES, GLOBAL_TYPES } from './types';
import { CurrentObject } from './types/rootState';

const defaultState: CurrentObject = {
  isCurrentObjectNowLoading: false,
  object: null,
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case OBJECT_EDIT_TYPES.CURRENT_OBJECT_GET_REQUEST_START:
      return {
        ...state,
        isCurrentObjectNowLoading: true,
      };
    case OBJECT_EDIT_TYPES.CURRENT_OBJECT_GET_REQUEST_END:
      return {
        ...state,
        isCurrentObjectNowLoading: false,
      };
    case OBJECT_EDIT_TYPES.CURRENT_OBJECT_INFO_ADD:
      return {
        ...state,
        object: { ...action.$payload },
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        isCurrentObjectNowLoading: false,
        object: null,
      };
    default:
      return state;
  }
};
