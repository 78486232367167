import { DOCUMENTS, GLOBAL_TYPES } from './types';
import { DocumentList } from './types/rootState';

const defaultState: DocumentList = {
  documentsList: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case DOCUMENTS.SET_DOCUMENTS:
      return {
        ...state,
        documentsList: [...action?.$payload?.content],
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        isObjectsListNowLoading: false,
        objects: [],
      };
    default:
      return state;
  }
};
