import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import { setApparatureList } from '../actions/apparature';
import { getAllApparature } from '../actions/sagas/apparature';

import { ResponseGenerator, sagaArgumentsType } from './types/types';

export function* addNewApparature({ $payload }: sagaArgumentsType) {
  try {
    let responceAddTO: ResponseGenerator | null = null;
    if ($payload.description) {
      responceAddTO = yield API.TO.addNewTO({
        description: $payload.description,
        maintenanceExpirationDate: $payload.maintenanceExpirationDate,
        latestMaintenanceDate: $payload.latestMaintenanceDate,
        maintenanceApproachDate: $payload.maintenanceApproachDate,
      });
    }
    yield API.apparature.addNewApparature({
      maintenanceId: responceAddTO?.data?.id ?? null,
      name: $payload.name,
      objectId: $payload.objectId,
      serialNumber: $payload.serialNumber,
      subtypeId: $payload.subtypeId,
      isMissingType: $payload.isMissingType,
      isMissingSubType: $payload.isMissingSubType,
      typeId: $payload.typeId,
    });
    yield put(notificationBySage('Оборудования создано успешно', 'success'));
    yield put(
      getAllApparature({
        objectIds: window.location.hash.split('=')[1],
      })
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка создания оборудования', 'error'));
    }
  }
}

export function* getAllApparatureSaga({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.apparature.getAllApparature({
      ...$payload,
      size: 9999,
    });

    yield put(setApparatureList(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения списка оборудования', 'error')
      );
    }
  }
}

export function* updateApparature({ $payload }: sagaArgumentsType) {
  try {
    let responceAddTO: ResponseGenerator | null = null;
    if ($payload.description && $payload.isToUpdate) {
      responceAddTO = yield API.TO.addNewTO({
        description: $payload.description,
        maintenanceExpirationDate: $payload.maintenanceExpirationDate,
        latestMaintenanceDate: $payload.latestMaintenanceDate,
        maintenanceApproachDate: $payload.maintenanceApproachDate,
      });
    }
    if ($payload.isTOSHouldDelete) {
      yield API.TO.removeTO($payload.currentTOid);
    }
    yield API.apparature.updateNewApparature({
      maintenanceId:
        $payload.description && $payload.isToUpdate
          ? responceAddTO?.data?.id
          : $payload.currentTOid,
      id: $payload.id,
      name: $payload.name,
      objectId: $payload.objectId,
      serialNumber: $payload.serialNumber,
      subtypeId: $payload.subtypeId,
      isMissingType: $payload.isMissingType,
      isMissingSubType: $payload.isMissingSubType,
      typeId: $payload.typeId,
    });

    yield put(notificationBySage('Оборудование обновлено успешно', 'success'));
    yield put(
      getAllApparature({
        objectIds: window.location.hash.split('=')[1],
        ...$payload.philtersParam,
      })
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка создания оборудования', 'error'));
    }
  }
}

export function* patchRemoveApparature({ $payload }: sagaArgumentsType) {
  try {
    yield API.apparature.removeApparature($payload.currentRemoveId);
    yield put(
      getAllApparature({
        objectIds: window.location.hash.split('=')[1],
        ...$payload.query,
      })
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка удаления оборудования', 'error'));
    }
  }
}
