import {
  PROJECTS_CONTROLLER_TYPES,
  ACTIVE_PROJECTS_CONTROLLER_TYPES,
} from '../reducers/types';
import { createAction } from './common';

export const setFullProjetsList = createAction(
  PROJECTS_CONTROLLER_TYPES.SET_FULL_PROJECTS_LIST
);
export const setProjectsListLoadStart = createAction(
  PROJECTS_CONTROLLER_TYPES.FULL_PROJECTS_LIST_LOAD_START
);
export const setProjectsListLoadEnd = createAction(
  PROJECTS_CONTROLLER_TYPES.FULL_PROJECTS_LIST_LOAD_END
);
export const setFullActiveProjectsList = createAction(
  ACTIVE_PROJECTS_CONTROLLER_TYPES.SET_FULL_PROJECTS_LIST
);
