import { CURRENT_USER_TYPES } from '../reducers/types';
import { createAction } from './common';

export const changeCurrentUserInfo = createAction(
  CURRENT_USER_TYPES.CHANGE_CURRENT_USER_INFO
);
export const setCurrentUserInfoLoadStart = createAction(
  CURRENT_USER_TYPES.LOAD_USER_INFO_START
);
export const setCurrentUserInfoLoadEnd = createAction(
  CURRENT_USER_TYPES.LOAD_USER_INFO_END
);
