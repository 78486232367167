import { OBJECT_CONTROLLER_TYPES } from '../../sagas/types';
import { createAction } from '../common';

const getObjectsList = createAction(
  OBJECT_CONTROLLER_TYPES.GET_FULL_OBJECTS_LIST
);
const postAddNewObject = createAction(OBJECT_CONTROLLER_TYPES.ADD_NEW_OBJECT);
const getCurrentObject = createAction(
  OBJECT_CONTROLLER_TYPES.GET_CURRENT_OBJECT
);
const deleteObject = createAction(OBJECT_CONTROLLER_TYPES.DELETE_OBJECT);
const getAllObjectsListDictionary = createAction(
  OBJECT_CONTROLLER_TYPES.GET_FULL_OBJECTS_LIST_DICTIONARY
);
const putUpdateObject = createAction(OBJECT_CONTROLLER_TYPES.UPDATE_OBJECT);

export {
  getObjectsList,
  postAddNewObject,
  getCurrentObject,
  getAllObjectsListDictionary,
  deleteObject,
  putUpdateObject,
};
