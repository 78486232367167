import { USER_CONTROLLER_TYPES, GLOBAL_TYPES } from './types';
import { CurrentUserList } from './types/rootState';

const defaultState: CurrentUserList = {
  isUserListNowLoading: false,
  users: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case USER_CONTROLLER_TYPES.SET_FULL_USERS_LIST:
      return {
        ...state,
        users: [...action?.$payload?.content],
      };
    case USER_CONTROLLER_TYPES.FULL_USERS_LIST_LOAD_START:
      return {
        ...state,
        isUserListNowLoading: true,
      };
    case USER_CONTROLLER_TYPES.FULL_USERS_LIST_LOAD_END:
      return {
        ...state,
        isUserListNowLoading: false,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        isUserListNowLoading: false,
        users: [],
      };
    default:
      return state;
  }
};
