import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import { getFiles } from '../actions/sagas/files';
import { setFileLoadStart, setFileLoadEnd } from '../actions/files';
import { setDocumentsList } from '../actions/documents';

import { ResponseGenerator, sagaArgumentsType } from './types/types';
import { deleteFileFromTree } from '../actions/filesTree';

export function* getAllFiles({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.files.getFiles({
      ...$payload,
      size: 9999,
    });
    yield put(setDocumentsList(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения списка файлов', 'error'));
    }
  }
}
export function* uploadFile({ $payload }: sagaArgumentsType) {
  try {
    // yield put(notificationBySage('Началась загрузка файла', 'warning'));
    yield put(setFileLoadStart());
    let formData = new window.FormData();
    formData.append('file', $payload.file);
    let params = { ...$payload };
    delete params.file;
    yield API.files.uploadFile(formData, {
      categoryId: $payload.categoryId,
      [$payload.categoryName]: $payload.objectId,
    });
    yield put(notificationBySage('Файл загружен успешно', 'success'));
    yield put(setFileLoadEnd());
    if ($payload.currentFilesParams) {
      yield put(getFiles($payload.currentFilesParams));
    } else {
      yield put(getFiles());
    }
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка загрузки файлов', 'error'));
    }
    yield put(setFileLoadEnd());
  }
}
export function* getDocumentInfo({ $payload }: sagaArgumentsType) {
  try {
    yield put(setFileLoadStart());
    let responce: ResponseGenerator = yield API.files.getSoloDocument($payload);
    let blob = new Blob([responce.data], { type: 'application/octet-stream' });
    let exportUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = exportUrl;
    let fileName = decodeURI(
      responce?.headers['content-disposition']?.split('filename=')[1]
    );
    link.download = fileName || '';
    link.click();
    yield put(setFileLoadEnd());
  } catch (err) {
    yield put(setFileLoadEnd());
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения файла', 'error'));
    }
  }
}
export function* removeDocument({ $payload }: sagaArgumentsType) {
  try {
    yield put(setFileLoadStart());
    let responce: ResponseGenerator = yield API.files.removeDocument(
      $payload.documentId
    );
    yield put(deleteFileFromTree(responce.data));
    yield put(notificationBySage('Документ успешно удален', 'success'));
    yield put(setFileLoadEnd());
    if ($payload.currentFilesParams) {
      yield put(getFiles($payload.currentFilesParams));
    } else {
      yield put(getFiles());
    }
  } catch (err) {
    yield put(setFileLoadEnd());
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка удаления документа', 'error'));
    }
  }
}
