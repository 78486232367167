import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../actions/notification';

const useNotification = () => {
  const dispatch = useDispatch();
  const createNotification = (
    message?: any,
    variant?: 'success' | 'error' | 'warning' | 'info'
  ) => {
    dispatch(
      enqueueSnackbar({
        message: message ?? '',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: variant ?? 'default',
        },
      })
    );
  };
  return createNotification;
};

const notificationBySage = (
  message?: any,
  variant?: 'success' | 'error' | 'warning' | 'info'
) =>
  enqueueSnackbar({
    message: message ?? '',
    options: {
      key: new Date().getTime() + Math.random(),
      variant: variant ?? 'default',
    },
  });
export { useNotification, notificationBySage };
