import { PROJECTS_CONTROLLER_TYPES } from '../../sagas/types';
import { createAction } from '../common';

const getProjectsList = createAction(
  PROJECTS_CONTROLLER_TYPES.GET_FULL_PROJECTS_LIST
);
const postAddNewProject = createAction(
  PROJECTS_CONTROLLER_TYPES.ADD_NEW_PROJECT
);
const putUpdateProject = createAction(PROJECTS_CONTROLLER_TYPES.UPDATE_PROJECT);
const getAllActiveProjects = createAction(
  PROJECTS_CONTROLLER_TYPES.GET_FULL_ACTIVE_PROJECTS_LIST
);

export {
  getProjectsList,
  postAddNewProject,
  putUpdateProject,
  getAllActiveProjects,
};
