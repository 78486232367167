import { APPARATURE_SUBTYPE_TYPES, GLOBAL_TYPES } from './types';
import { ApparatureSubCategories } from './types/rootState';

const defaultState: ApparatureSubCategories = {
  apparatureSubCategoriesList: [],
  totalPages: 0,
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case APPARATURE_SUBTYPE_TYPES.SET_APPARATURE_SUBTYPE_TYPES:
      return {
        ...state,
        apparatureSubCategoriesList: [...action?.$payload?.content],
        totalPages: action?.$payload?.totalElements,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        apparatureSubCategoriesList: [],
      };
    default:
      return state;
  }
};
