import { AUTHORIZATION_TYPES } from '../../sagas/types';
import { createAction } from '../common';

export const loginUser = createAction(AUTHORIZATION_TYPES.ATHORIZATION);
export const getCurrentUserInfoById = createAction(
  AUTHORIZATION_TYPES.GET_CURRENT_USER_INFO_BY_ID
);
export const getCurrentUserInfo = createAction(
  AUTHORIZATION_TYPES.GET_CURRENT_USER_INFO
);
export const logoutUser = createAction(AUTHORIZATION_TYPES.LOGOUT);
