import { APPARATURE_TYPES, GLOBAL_TYPES } from './types';
import { Apparature } from './types/rootState';

const defaultState: Apparature = {
  apparatureList: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case APPARATURE_TYPES.SET_APPARATURE_LIST:
      return {
        ...state,
        apparatureList: [...action?.$payload?.content],
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        apparatureCategoriesList: [],
      };
    default:
      return state;
  }
};
