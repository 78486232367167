import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useSelector } from 'react-redux';
// import IconButton from '@material-ui/core/IconButton';
// import { useSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import { RootState, UserState } from '../../reducers/types/rootState';

import styles from './loginModule.module.scss';

interface userLoginModuleInterface {
  onChangeUserLogin: (
    event: React.FormEvent<HTMLFormElement>,
    login: string,
    password: string
  ) => void;
  userEmailError: boolean;
  userPasswordError: boolean;
}

const LoginModule = ({
  onChangeUserLogin,
  userEmailError,
  userPasswordError,
}: userLoginModuleInterface) => {
  const currentUserInfo: UserState = useSelector(
    (store: RootState) => store.currentUser
  );
  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordIsVisible, setPasswordIsVisible] = React.useState(false);
  const handleChangeLogin = (e: { target: { value: string } }) => {
    if (e.target.value.length < 21) {
      setLogin(e.target.value);
    }
  };
  const handleChangePasswordVisibility = () => {
    setPasswordIsVisible(!passwordIsVisible);
  };
  const handleChangePassword = (e: { target: { value: string } }) => {
    let myRe = /^[a-zA-Z0-9!@#$%^&*]+$/;
    if (
      (myRe.exec(e.target.value) || e.target.value === '') &&
      e.target.value.length < 21
    ) {
      setPassword(e.target.value);
    }
  };
  return (
    <form
      className="fullAuthBlock"
      onSubmit={event => {
        onChangeUserLogin(event, login, password);
      }}
    >
      <div>
        <div className={styles.inputBlock}>
          <TextField
            label="Имя пользователя"
            variant="outlined"
            data-testid="email-input"
            value={login}
            onChange={handleChangeLogin}
            error={userEmailError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.inputBlock}>
          <TextField
            label="Пароль"
            variant="outlined"
            value={password}
            data-testid="password-input"
            onChange={handleChangePassword}
            className="inputBlock"
            error={userPasswordError}
            type={passwordIsVisible ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {passwordIsVisible ? (
                    <VisibilityOffIcon
                      onClick={handleChangePasswordVisibility}
                      className={styles.passwordChangeVisibility}
                      data-testid="unShowPassword"
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={handleChangePasswordVisibility}
                      className={styles.passwordChangeVisibility}
                      data-testid="showPassword"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.mainFormButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            data-testid="submit-form-button"
            disabled={currentUserInfo.isUserInfoNowLoading}
          >
            Войти
          </Button>
        </div>
      </div>
    </form>
  );
};

export { LoginModule };
