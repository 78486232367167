import React from 'react';
// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  loginUser,
  getCurrentUserInfo,
} from '../../actions/sagas/authorization';
// import { getCurrentUserInfoById } from '../../actions/sagas/users';

import { LoginModule } from './loginModule';
import { useNotification } from '../../helpers/notificator/useNotificator';
import { setCurrentUserInfoLoadStart } from '../../actions/currentUser';

// import { RegistrationEnterSwitch } from './registrationEnterSwitch';
// import { RegistrationModule } from './registrationModule';
import { RootState, UserState } from '../../reducers/types/rootState';

import Styles from './authorizationModule.module.scss';

interface AuthorizationModuleInterface {
  children: any;
}
const authorizeSchema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().required(),
});
export const AuthorizationModule = ({
  children,
}: AuthorizationModuleInterface) => {
  // const [isLoginOpen, setIsLoginOpen] = React.useState(true);
  // const [loginError, setLoginError] = React.useState(false);
  const dispatch = useDispatch();
  const currentUserInfo: UserState = useSelector(
    (store: RootState) => store.currentUser
  );

  // const [isUserLogin, setIsUserLogin] = React.useState(false);
  const [userEmailError, setUserEmailError] = React.useState(false);
  const [userPasswordError, setUserPasswordError] = React.useState(false);
  const addNotification = useNotification();
  React.useEffect(() => {
    if (localStorage.getItem('authToken')) {
      dispatch(setCurrentUserInfoLoadStart());
      dispatch(getCurrentUserInfo());
    } else {
      localStorage.removeItem('authToken');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (window.location.hash !== '/') {
      window.location.href = '/#/';
    }
  });
  // const handlerChangeIsLoginOpen = () => {
  //   setIsLoginOpen(!isLoginOpen);
  // };
  const handleUserLogin = (
    event: React.FormEvent<HTMLFormElement>,
    login: string,
    password: string
  ) => {
    event.preventDefault();
    authorizeSchema
      .validate(
        {
          login,
          password,
        },
        { abortEarly: false }
      )
      .then(valid => {
        setUserEmailError(false);
        setUserPasswordError(false);
        dispatch(setCurrentUserInfoLoadStart());
        dispatch(loginUser({ login, password }));
        // setIsUserLogin(!isUserLogin);
      })
      .catch(error => {
        setUserEmailError(false);
        setUserPasswordError(false);
        addNotification('Введен неверный логин или пароль', 'error');
        let errorsKeys = Object.keys(error.errors);
        for (let i = 0; i < errorsKeys.length; i += 1) {
          switch (error.errors[errorsKeys[i]]) {
            case 'login must be a valid email':
            case 'login is a required field':
              setUserEmailError(true);
              break;
            case 'password is a required field':
              setUserPasswordError(true);
              break;
            default:
              break;
          }
        }
      });
  };
  if (currentUserInfo.id && currentUserInfo.status === 'ACTIVE') {
    return children;
  }
  return (
    <div className={Styles.background}>
      {/* <div>
        <RegistrationEnterSwitch
          isLoginOpen={isLoginOpen}
          handleChangeIsLoginOpen={handlerChangeIsLoginOpen}
        />
      </div> */}
      <div className={Styles.registrationForms}>
        <div className={Styles.registrationHeader}>
          <div>Вход на портал</div>
        </div>
        <div className={Styles.fullAuthBlock}>
          <div>
            <div>
              <LoginModule
                onChangeUserLogin={handleUserLogin}
                userEmailError={userEmailError}
                userPasswordError={userPasswordError}
              />
              {
                // isLoginOpen
                //   ? (
                //   ) : <RegistrationModule />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
