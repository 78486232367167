import { FILTERS_APRRATURE, GLOBAL_TYPES } from './types';
import { ApparatureFiler } from './types/rootState';

const defaultState: ApparatureFiler = {
  apparatureFIlterTypes: [],
  apparatureFIlterSubTypes: [],
  apparatureName: '',
  apparatureSerial: '',
  isTypeFilterOpen: false,
  isSubTypeFilterOpen: false,
};
export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case FILTERS_APRRATURE.CHANGE_NAME_FILTER:
      return {
        ...state,
        apparatureName: action.$payload.text,
      };
    case FILTERS_APRRATURE.CHANGE_SERIAL_FILTER:
      return {
        ...state,
        apparatureSerial: action.$payload.text,
      };
    case FILTERS_APRRATURE.CHANGE_TYPES_FILTER:
      return {
        ...state,
        apparatureFIlterTypes: action.$payload,
      };
    case FILTERS_APRRATURE.CLEAR_TYPES_FILTER:
      return {
        ...state,
        apparatureFIlterTypes: [],
      };
    case FILTERS_APRRATURE.CHANGE_SUB_TYPES_FILTER:
      return {
        ...state,
        apparatureFIlterSubTypes: action.$payload,
      };
    case FILTERS_APRRATURE.CLEAR_SUB_TYPES_FILTER:
      return {
        ...state,
        apparatureFIlterSubTypes: [],
      };
    case FILTERS_APRRATURE.PHILTER_TYPE_IS_OPEN:
      return {
        ...state,
        isTypeFilterOpen: !state.isTypeFilterOpen,
      };
    case FILTERS_APRRATURE.PHILTER_SUB_TYPE_IS_OPEN:
      return {
        ...state,
        isSubTypeFilterOpen: !state.isSubTypeFilterOpen,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        isObjectsListNowLoading: false,
        objects: [],
      };
    default:
      return state;
  }
};
