import { put, select } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import { setFullUserList, setUserListLoadEnd } from '../actions/users';
import { getFullUsersList } from '../actions/sagas/users';
import { ResponseGenerator, sagaArgumentsType } from './types/types';
import { RootState, UserState } from '../reducers/types/rootState';

export function* getAllUsers({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.users.getAllUsers({
      sort: $payload?.sort ?? [],
      lastName: $payload?.lastName ?? '',
      size: 99999,
    });
    yield put(setFullUserList(responce.data));
    yield put(setUserListLoadEnd());
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      yield put(setUserListLoadEnd());
    } else {
      yield put(
        notificationBySage('Ошибка получения списка пользователей', 'error')
      );
      yield put(setUserListLoadEnd());
    }
  }
}

export function* removeUserById({ $payload }: sagaArgumentsType) {
  try {
    yield API.users.removeUserWithId($payload.id);
    yield put(getFullUsersList());
    yield put(notificationBySage('Пользователь успешно удален', 'success'));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка удаление пользователя', 'error'));
    }
  }
}

export function* updateUserInfoById({ $payload }: sagaArgumentsType) {
  try {
    yield API.users.updateUserById($payload.id, {
      ...$payload,
    });
    const userInfo: UserState = yield select(
      (state: RootState) => state.currentUser
    );
    if (userInfo.role === 'ROLE_ADMIN') {
      yield put(getFullUsersList());
    }
    yield put(notificationBySage('Пользователь успешно обновлен', 'success'));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка обновления пользователя', 'error'));
    }
  }
}

export function* addNewUser({ $payload }: sagaArgumentsType) {
  try {
    yield API.users.addUser($payload);
    yield put(getFullUsersList());
    yield put(notificationBySage('Пользователь успешно создан', 'success'));
  } catch (err) {
    yield put(getFullUsersList());
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка создания пользователя', 'error'));
    }
  }
}

export function* blockUserById({ $payload }: sagaArgumentsType) {
  try {
    yield API.users.blockUserWithId($payload.id);
    yield put(getFullUsersList());
    yield put(
      notificationBySage('Пользователь успешно заблокирован', 'success')
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка блокировки пользователя', 'error'));
    }
  }
}

export function* unBlockUserById({ $payload }: sagaArgumentsType) {
  try {
    yield API.users.unBlockUserWithId($payload.id);
    yield put(getFullUsersList());
    yield put(
      notificationBySage('Пользователь успешно разблокирован', 'success')
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка разблокировки пользователя', 'error')
      );
    }
  }
}
