const NOTIFICATION_TYPES = {
  ENQUEUE_SNACKBAR: '[REDUX][NOTIFICATION_TYPES][ENQUEUE_SNACKBAR]',
  CLOSE_SNACKBAR: '[REDUX][NOTIFICATION_TYPES][CLOSE_SNACKBAR]',
  REMOVE_SNACKBAR: '[REDUX][NOTIFICATION_TYPES][REMOVE_SNACKBAR]',
};

const CURRENT_USER_TYPES = {
  CHANGE_CURRENT_USER_INFO:
    '[REDUX][CURRENT_USER_TYPES][CHANGE_CURRENT_USER_INFO]',
  LOAD_USER_INFO_START: '[REDUX][CURRENT_USER_TYPES][LOAD_USER_INFO_START]',
  LOAD_USER_INFO_END: '[REDUX][CURRENT_USER_TYPES][LOAD_USER_INFO_END]',
};
const ADMINS_TYPES = {
  ADMIN_REQUEST_LOADING_START:
    '[REDUX][ADMINS_TYPES][ADMIN_REQUEST_LOADING_START]',
  ADMIN_REQUEST_LOADING_END: '[REDUX][ADMINS_TYPES][ADMIN_REQUEST_LOADING_END]',
};

const USER_CONTROLLER_TYPES = {
  SET_FULL_USERS_LIST: '[REDUX][USER_CONTROLLER_TYPES][SET_FULL_USERS_LIST]',
  FULL_USERS_LIST_LOAD_START:
    '[REDUX][USER_CONTROLLER_TYPES][FULL_USERS_LIST_LOAD_START]',
  FULL_USERS_LIST_LOAD_END:
    '[REDUX][USER_CONTROLLER_TYPES][FULL_USERS_LIST_LOAD_END]',
};

const PROJECTS_CONTROLLER_TYPES = {
  SET_FULL_PROJECTS_LIST:
    '[REDUX][PROJECTS_CONTROLLER_TYPES][SET_FULL_PROJECTS_LIST]',
  FULL_PROJECTS_LIST_LOAD_START:
    '[REDUX][PROJECTS_CONTROLLER_TYPES][FULL_PROJECTS_LIST_LOAD_START]',
  FULL_PROJECTS_LIST_LOAD_END:
    '[REDUX][PROJECTS_CONTROLLER_TYPES][FULL_PROJECTS_LIST_LOAD_END]',
};
const ACTIVE_PROJECTS_CONTROLLER_TYPES = {
  SET_FULL_PROJECTS_LIST:
    '[REDUX][ACTIVE_PROJECTS_CONTROLLER_TYPES][SET_FULL_PROJECTS_LIST]',
  FULL_PROJECTS_LIST_LOAD_START:
    '[REDUX][ACTIVE_PROJECTS_CONTROLLER_TYPES][FULL_PROJECTS_LIST_LOAD_START]',
  FULL_PROJECTS_LIST_LOAD_END:
    '[REDUX][ACTIVE_PROJECTS_CONTROLLER_TYPES][FULL_PROJECTS_LIST_LOAD_END]',
};
const OBJECTS_CONTROLLER_TYPES = {
  SET_FULL_OBJECTS_LIST:
    '[REDUX][OBJECTS_CONTROLLER_TYPES][SET_FULL_OBJECTS_LIST]',
  SET_FULL_OBJECTS_LIST_DICTIONARY:
    '[REDUX][OBJECTS_CONTROLLER_TYPES][SET_FULL_OBJECTS_LIST_DICTIONARY]',
};
const OBJECT_EDIT_TYPES = {
  CURRENT_OBJECT_GET_REQUEST_START:
    '[REDUX][OBJECT_EDIT_TYPES][CURRENT_OBJECT_GET_REQUEST_START]',
  CURRENT_OBJECT_GET_REQUEST_END:
    '[REDUX][OBJECT_EDIT_TYPES][CURRENT_OBJECT_GET_REQUEST_END]',
  CURRENT_OBJECT_INFO_ADD:
    '[REDUX][OBJECT_EDIT_TYPES][CURRENT_OBJECT_INFO_ADD]',
};
const DOCUMENT_CATEGORIEST_TYPES = {
  SET_DOCUMENTS_CATEGORIES_TYPES:
    '[REDUX][DOCUMENT_CATEGORIEST_TYPES][SET_DOCUMENTS_CATEGORIES_TYPES]',
  ADD_DOCUMENTS_CATEGORIES_TYPES:
    '[REDUX][DOCUMENT_CATEGORIES_LIST][ADD_DOCUMENTS_CATEGORIES_TYPES]',
};
const APPARATURE_TYPE_TYPES = {
  SET_APPARATURE_TYPE_TYPES:
    '[REDUX][APPARATURE_TYPE_TYPES][SET_APPARATURE_TYPE_TYPES]',
  SET_APPARATURE_TYPE_FOR_SELECT:
    '[REDUX][APPARATURE_TYPE_TYPES][SET_APPARATURE_TYPE_FOR_SELECT]',
};
const APPARATURE_SUBTYPE_TYPES = {
  SET_APPARATURE_SUBTYPE_TYPES:
    '[REDUX][APPARATURE_SUBTYPE_TYPES][SET_APPARATURE_SUBTYPE_TYPES]',
};
const TO_TYPES = {
  SET_TO_TYPES_TYPES: '[REDUX][TO_TYPES][SET_TO_TYPES_TYPES]',
};
const APPARATURE_TYPES = {
  SET_APPARATURE_LIST: '[REDUX][APPARATURE_TYPES][SET_APPARATURE_LIST]',
};
const GLOBAL_TYPES = {
  CLEAR_ALL_INFO_FROM_REDUCER:
    '[REDUX][GLOBAL_TYPES][CLEAR_ALL_INFO_FROM_REDUCER]',
  CLEAR_ALL_TYPES_FOR_SELECT_INFO_FROM_REDUCER:
    '[REDUX][GLOBAL_TYPES][CLEAR_ALL_TYPES_FOR_SELECT_INFO_FROM_REDUCER]',
};
const DOCUMENTS = {
  SET_DOCUMENTS: '[REDUX][DOCUMENTS][SET_DOCUMENTS]',
};
const WORK_STADIES = {
  SET_WORK_STADIES: '[REDUX][WORK_STADIES][SET_WORK_STADIES]',
};
const FILE_LOAD_STATUS = {
  SET_FILE_LOAD_START: '[REDUX][FILE_LOAD_STATUS][SET_FILE_LOAD_START]',
  SET_FILE_LOAD_END: '[REDUX][FILE_LOAD_STATUS][SET_FILE_LOAD_END]',
};
const FILTERS_APRRATURE = {
  CHANGE_TYPES_FILTER: '[REDUX][FILTERS_APRRATURE][CHANGE_TYPES_FILTER]',
  CLEAR_TYPES_FILTER: '[REDUX][FILTERS_APRRATURE][CLEAR_TYPES_FILTER]',
  PHILTER_TYPE_IS_OPEN: '[REDUX][FILTERS_APRRATURE][PHILTER_TYPE_IS_OPEN]',

  CHANGE_SUB_TYPES_FILTER:
    '[REDUX][FILTERS_APRRATURE][CHANGE_SUB_TYPES_FILTER]',
  CLEAR_SUB_TYPES_FILTER: '[REDUX][FILTERS_APRRATURE][CLEAR_SUB_TYPES_FILTER]',
  PHILTER_SUB_TYPE_IS_OPEN:
    '[REDUX][FILTERS_APRRATURE][PHILTER_SUB_TYPE_IS_OPEN]',

  CHANGE_NAME_FILTER: '[REDUX][FILTERS_APRRATURE][CHANGE_NAME_FILTER]',
  CHANGE_SERIAL_FILTER: '[REDUX][FILTERS_APRRATURE][CHANGE_SERIAL_FILTER]',

  // apparatureFIlterTypes: [],
  // apparatureFIlterSubTypes: [],
  // apparatureName: '',
  // apparatureSerial: '',
};
const FILES_TREE = {
  SET_ALL_PROJECTS: '[REDUX][FILES_TREE][SET_ALL_PROJECTS]',
  SET_OBJECTS: '[REDUX][FILES_TREE][SET_OBJECTS]',
  SET_EQUIPMETS: '[REDUX][FILES_TREE][SET_EQUIPMETS]',
  SET_PROJECT: '[REDUX][FILES_TREE][SET_PROJECT]',
  DELETE_FILE_FROM_TREE: '[REDUX][FILES_TREE][DELETE_FILE_FROM_TREE]',
};
export {
  NOTIFICATION_TYPES,
  CURRENT_USER_TYPES,
  ADMINS_TYPES,
  USER_CONTROLLER_TYPES,
  PROJECTS_CONTROLLER_TYPES,
  OBJECTS_CONTROLLER_TYPES,
  GLOBAL_TYPES,
  OBJECT_EDIT_TYPES,
  DOCUMENT_CATEGORIEST_TYPES,
  APPARATURE_TYPE_TYPES,
  APPARATURE_SUBTYPE_TYPES,
  TO_TYPES,
  APPARATURE_TYPES,
  DOCUMENTS,
  WORK_STADIES,
  FILTERS_APRRATURE,
  ACTIVE_PROJECTS_CONTROLLER_TYPES,
  FILE_LOAD_STATUS,
  FILES_TREE,
};
