import {
  DOCUMENT_CATEGORIEST_TYPES,
  APPARATURE_TYPE_TYPES,
  APPARATURE_SUBTYPE_TYPES,
  TO_TYPES,
  WORK_STADIES,
  GLOBAL_TYPES,
} from '../reducers/types';
import { createAction } from './common';

export const setDocumentsCategories = createAction(
  DOCUMENT_CATEGORIEST_TYPES.SET_DOCUMENTS_CATEGORIES_TYPES
);

export const addDocumentsCategories = createAction(
  DOCUMENT_CATEGORIEST_TYPES.ADD_DOCUMENTS_CATEGORIES_TYPES
);

export const setApparatureTypes = createAction(
  APPARATURE_TYPE_TYPES.SET_APPARATURE_TYPE_TYPES
);

export const setApparatureTypesForSelect = createAction(
  APPARATURE_TYPE_TYPES.SET_APPARATURE_TYPE_FOR_SELECT
);
export const setApparatureSubTypes = createAction(
  APPARATURE_SUBTYPE_TYPES.SET_APPARATURE_SUBTYPE_TYPES
);

export const setTOTypesList = createAction(TO_TYPES.SET_TO_TYPES_TYPES);

export const setWorkStadiesList = createAction(WORK_STADIES.SET_WORK_STADIES);

export const cleanApparatureTypes = createAction(
  GLOBAL_TYPES.CLEAR_ALL_TYPES_FOR_SELECT_INFO_FROM_REDUCER
);
