import { NOTIFICATION_TYPES } from '../reducers/types';

interface NotificationInterface {
  message: any;
  options: { variant: string; key: any };
}
export const enqueueSnackbar = (notification: NotificationInterface) => {
  const key = notification.options && notification.options.key;
  return {
    type: NOTIFICATION_TYPES.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key: any) => ({
  type: NOTIFICATION_TYPES.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key: any) => ({
  type: NOTIFICATION_TYPES.REMOVE_SNACKBAR,
  key,
});
