import { ACTIVE_PROJECTS_CONTROLLER_TYPES, GLOBAL_TYPES } from './types';
import { CurrentProjecList } from './types/rootState';

const defaultState: CurrentProjecList = {
  isProjectListNowLoading: false,
  projects: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case ACTIVE_PROJECTS_CONTROLLER_TYPES.SET_FULL_PROJECTS_LIST:
      return {
        ...state,
        projects: [...action?.$payload?.content],
      };
    case ACTIVE_PROJECTS_CONTROLLER_TYPES.FULL_PROJECTS_LIST_LOAD_START:
      return {
        ...state,
        isProjectListNowLoading: true,
      };
    case ACTIVE_PROJECTS_CONTROLLER_TYPES.FULL_PROJECTS_LIST_LOAD_END:
      return {
        ...state,
        isProjectListNowLoading: false,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        isProjectListNowLoading: false,
        projects: [],
      };
    default:
      return state;
  }
};
