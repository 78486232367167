import { combineReducers } from 'redux';
import notifications from './notifications';
import currentUser from './currentUserInfo';
import usersList from './fullUsersList';
import projects from './fullProjectsList';
import objects from './fullObjectsList';
import object from './currentEditedObject';
import documentsCategories from './documentsCategories';
import apparatureCategories from './apparatureTypes';
import apparatureSubCategories from './apparatureSubTypes';
import TOCategories from './TOtypes';
import apparatureList from './currentObjectApparatureList';
import fullObjectsListDictionary from './fullObjectsListDictionary';
import documentsList from './documentsList';
import workStadiesList from './workStadies';
import apparatureFiler from './filterApparatureObject';
import activeProjectsList from './fullProjectsListActiveProjects';
import fileLoad from './fileLoadStatus';
import filesTreeList from './filesTreeList';

const CombinedReducer = () =>
  combineReducers({
    notifications,
    currentUser,
    usersList,
    projects,
    objects,
    object,
    documentsCategories,
    apparatureCategories,
    apparatureSubCategories,
    TOCategories,
    apparatureList,
    fullObjectsListDictionary,
    documentsList,
    workStadiesList,
    apparatureFiler,
    activeProjectsList,
    fileLoad,
    filesTreeList,
  });

export default CombinedReducer;
