import { WORK_STADIES, GLOBAL_TYPES } from './types';
import { WorkStadiesList } from './types/rootState';

const defaultState: WorkStadiesList = {
  workStadiesList: [],
  workStadiesListNum: 0,
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case WORK_STADIES.SET_WORK_STADIES:
      return {
        ...state,
        workStadiesList: [...action?.$payload?.content],
        workStadiesListNum: action?.$payload?.totalElements,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        workStadiesList: [],
      };
    default:
      return state;
  }
};
