import { OBJECTS_CONTROLLER_TYPES, GLOBAL_TYPES } from './types';
import { CurrentObjectsList } from './types/rootState';

const defaultState: CurrentObjectsList = {
  isObjectsListNowLoading: false,
  objects: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case OBJECTS_CONTROLLER_TYPES.SET_FULL_OBJECTS_LIST_DICTIONARY:
      return {
        ...state,
        objects: [
          ...action?.$payload?.content.sort((a: any, b: any) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          }),
        ],
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        isObjectsListNowLoading: false,
        objects: [],
      };
    default:
      return state;
  }
};
