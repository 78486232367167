import { FILE_LOAD_STATUS, GLOBAL_TYPES } from './types';
import { FileLoadStatus } from './types/rootState';

const defaultState: FileLoadStatus = {
  fileNowLoading: false,
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case FILE_LOAD_STATUS.SET_FILE_LOAD_START:
      return {
        fileNowLoading: true,
      };
    case FILE_LOAD_STATUS.SET_FILE_LOAD_END:
      return {
        fileNowLoading: false,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        fileNowLoading: false,
      };
    default:
      return state;
  }
};
