import { USER_CONTROLLER_TYPES } from '../../sagas/types';
import { createAction } from '../common';

const getCurrentUserInfoById = createAction(
  USER_CONTROLLER_TYPES.GET_CURRENT_USER_INFO_BY_ID
);
const getFullUsersList = createAction(
  USER_CONTROLLER_TYPES.GET_FULL_USERS_LIST
);
const postRemoveUserWithId = createAction(
  USER_CONTROLLER_TYPES.REMOVE_USER_WITH_ID
);
const putUpdateUserWithId = createAction(
  USER_CONTROLLER_TYPES.UPDATE_USER_WITH_ID
);
const postAddUser = createAction(USER_CONTROLLER_TYPES.ADD_USER);
const patchBlockUser = createAction(USER_CONTROLLER_TYPES.BLOCK_USER);
const patchUnBlockUser = createAction(USER_CONTROLLER_TYPES.UN_BLOCK_USER);

export {
  getCurrentUserInfoById,
  getFullUsersList,
  postRemoveUserWithId,
  putUpdateUserWithId,
  postAddUser,
  patchBlockUser,
  patchUnBlockUser,
};
