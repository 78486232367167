import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import {
  changeCurrentUserInfo,
  setCurrentUserInfoLoadEnd,
} from '../actions/currentUser';
import { clearStore } from '../actions/globalactions';

import { ResponseGenerator, sagaArgumentsType } from './types/types';

export function* authorizeUser({ $payload }: sagaArgumentsType) {
  try {
    const responce: ResponseGenerator = yield API.auth.login(
      $payload.login,
      $payload.password
    );
    yield put(setCurrentUserInfoLoadEnd());
    yield put(changeCurrentUserInfo(responce?.data));
    yield put(
      notificationBySage('Пользователь успешно авторизирован', 'success')
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      yield put(setCurrentUserInfoLoadEnd());
    } else {
      yield put(
        notificationBySage('Введен неверный логин или пароль', 'error')
      );
      yield put(setCurrentUserInfoLoadEnd());
    }
  }
}

export function* getUserInfoById({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.users.getById($payload);
    yield put(setCurrentUserInfoLoadEnd());
    yield put(changeCurrentUserInfo(responce?.data));
    yield put(
      notificationBySage(
        `Добро пожаловать ${responce?.data.lastName ?? ''}  ${responce?.data
          .firstName ?? ''} `,
        'success'
      )
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      localStorage.removeItem('authToken');
      yield put(setCurrentUserInfoLoadEnd());
    } else {
      yield put(
        notificationBySage('Ошибка получения данных о пользователе', 'error')
      );
      localStorage.removeItem('authToken');
      yield put(setCurrentUserInfoLoadEnd());
    }
  }
}

export function* getUserInfo() {
  try {
    let responce: ResponseGenerator = yield API.users.getCurrent();
    yield put(setCurrentUserInfoLoadEnd());
    yield put(changeCurrentUserInfo(responce?.data));
    yield put(
      notificationBySage(
        `Добро пожаловать ${responce?.data.lastName ?? ''}  ${responce?.data
          .firstName ?? ''} `,
        'success'
      )
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      localStorage.removeItem('authToken');
      yield put(setCurrentUserInfoLoadEnd());
    } else {
      yield put(
        notificationBySage('Ошибка получения данных о пользователе', 'error')
      );
      localStorage.removeItem('authToken');
      yield put(setCurrentUserInfoLoadEnd());
    }
  }
}

export function* logoutUser() {
  try {
    yield API.logout.logout();
    yield put(notificationBySage('Успешный выход из системы', 'success'));
    yield put(clearStore());
    localStorage.removeItem('authToken');
    localStorage.removeItem('currentUserId');
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка при выходе из системы', 'error'));
    }
  }
}
