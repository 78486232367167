export const AUTHORIZATION_TYPES = {
  ATHORIZATION: '[SAGAS][AUTHORIZATION_TYPES][ATHORIZATION]',
  GET_CURRENT_USER_INFO_BY_ID:
    '[SAGAS][AUTHORIZATION_TYPES][GET_CURRENT_USER_INFO_BY_ID]',
  GET_CURRENT_USER_INFO: '[SAGAS][AUTHORIZATION_TYPES][GET_CURRENT_USER_INFO]',
  LOGOUT: '[SAGAS][AUTHORIZATION_TYPES][LOGOUT]',
};
export const USER_CONTROLLER_TYPES = {
  GET_CURRENT_USER_INFO_BY_ID:
    '[SAGAS][USER_CONTROLLER_TYPES][GET_CURRENT_USER_INFO_BY_ID]',
  GET_FULL_USERS_LIST: '[SAGAS][USER_CONTROLLER_TYPES][GET_FULL_USERS_LIST]',
  REMOVE_USER_WITH_ID: '[SAGAS][USER_CONTROLLER_TYPES][REMOVE_USER_WITH_ID]',
  UPDATE_USER_WITH_ID: '[SAGAS][USER_CONTROLLER_TYPES][UPDATE_USER_WITH_ID]',
  ADD_USER: '[SAGAS][USER_CONTROLLER_TYPES][ADD_USER]',
  BLOCK_USER: '[SAGAS][USER_CONTROLLER_TYPES][BLOCK_USER]',
  UN_BLOCK_USER: '[SAGAS][USER_CONTROLLER_TYPES][UN_BLOCK_USER]',
};

export const OBJECT_CONTROLLER_TYPES = {
  GET_FULL_OBJECTS_LIST:
    '[SAGAS][OBJECT_CONTROLLER_TYPES][GET_FULL_OBJECTS_LIST]',
  GET_FULL_OBJECTS_LIST_DICTIONARY:
    '[SAGAS][OBJECT_CONTROLLER_TYPES][GET_FULL_OBJECTS_LIST_DICTIONARY]',
  ADD_NEW_OBJECT: '[SAGAS][OBJECT_CONTROLLER_TYPES][ADD_NEW_OBJECT]',
  GET_CURRENT_OBJECT: '[SAGAS][OBJECT_CONTROLLER_TYPES][GET_CURRENT_OBJECT]',
  DELETE_OBJECT: '[SAGAS][OBJECT_CONTROLLER_TYPES][DELETE_OBJECT]',
  UPDATE_OBJECT: '[SAGAS][OBJECT_CONTROLLER_TYPES][UPDATE_OBJECT]',
};
export const PROJECTS_CONTROLLER_TYPES = {
  GET_FULL_PROJECTS_LIST:
    '[SAGAS][PROJECTS_CONTROLLER_TYPES][GET_FULL_PROJECTS_LIST]',
  ADD_NEW_PROJECT: '[SAGAS][PROJECTS_CONTROLLER_TYPES][ADD_NEW_PROJECT]',
  UPDATE_PROJECT: '[SAGAS][PROJECTS_CONTROLLER_TYPES][UPDATE_PROJECT]',
  GET_FULL_ACTIVE_PROJECTS_LIST:
    '[SAGAS][PROJECTS_CONTROLLER_TYPES][GET_FULL_PROJECTS_LIST]',
};
export const CATALOGS = {
  GET_FULL_DOCUMENTS_CATEGORIES_LIST:
    '[SAGAS][CATALOGS][GET_FULL_DOCUMENTS_CATEGORIES_LIST]',
  ADD_DOCUMENT_CATEGORIES_LIST:
    '[SAGAS][CATALOGS][ADD_DOCUMENTS_CATEGORIES_LIST]',
  ADD_DOCUMENT_CATEGORIE: '[SAGAS][CATALOGS][ADD_DOCUMENT_CATEGORIE]',
  REMOVE_DOCUMENT_CATEGORIE: '[SAGAS][CATALOGS][REMOVE_DOCUMENT_CATEGORIE]',
  EDIT_DOCUMENT_CATEGORIE: '[SAGAS][CATALOGS][EDIT_DOCUMENT_CATEGORIE]',
  GET_FULL_APPARATURE_TYPES_LIST:
    '[SAGAS][CATALOGS][GET_FULL_APPARATURE_TYPES_LIST]',
  GET_ALL_APPARATURE_FOR_SELECT:
    '[SAGAS][CATALOGS][GET_ALL_APPARATURE_FOR_SELECT]',
  ADD_APPARATURE_TYPE: '[SAGAS][CATALOGS][ADD_APPARATURE_TYPE]',
  REMOVE_APPARATURE_TYPE: '[SAGAS][CATALOGS][REMOVE_APPARATURE_TYPE]',
  EDIT_APPARATURE_TYPE: '[SAGAS][CATALOGS][EDIT_APPARATURE_TYPE]',

  GET_FULL_APPARATURE_SUBTYPES_LIST:
    '[SAGAS][CATALOGS][GET_FULL_APPARATURE_SUBTYPES_LIST]',
  ADD_APPARATURE_SUBTYPE: '[SAGAS][CATALOGS][ADD_APPARATURE_SUBTYPE]',
  REMOVE_APPARATURE_SUBTYPE: '[SAGAS][CATALOGS][REMOVE_APPARATURE_SUBTYPE]',
  EDIT_APPARATURE_SUBTYPE: '[SAGAS][CATALOGS][EDIT_APPARATURE_SUBTYPE]',

  GET_TO_STATUS_LIST: '[SAGAS][CATALOGS][GET_TO_STATUS_LIST]',
  EDIT_TO_STATUS: '[SAGAS][CATALOGS][EDIT_TO_STATUS]',

  GET_WORK_STADIES_LIST: '[SAGAS][CATALOGS][GET_WORK_STADIES_LIST]',
  EDIT_WORK_STADIE: '[SAGAS][CATALOGS][EDIT_WORK_STADIE]',
  ADD_WORK_STADIE: '[SAGAS][CATALOGS][ADD_WORK_STADIE]',
  REMOVE: '[SAGAS][CATALOGS][REMOVE]',
};
export const APPARATURE = {
  GET_ALL_APPARATURE: '[SAGAS][APPARATURE][GET_ALL_APPARATURE]',
  ADD_NEW_APPARATURE: '[SAGAS][APPARATURE][ADD_NEW_APPARATURE]',
  UPDATE_APPARATURE: '[SAGAS][APPARATURE][UPDATE_APPARATURE]',
  REMOVE_APPARATURE: '[SAGAS][APPARATURE][REMOVE_APPARATURE]',
};
export const FILES = {
  UPLOAD_FILE: '[SAGAS][FILES][UPLOAD_FILE]',
  GET_FILES: '[SAGAS][FILES][GET_FILES]',
  GET_FILE_FULL_INFO: '[SAGAS][FILES][GET_FILE_FULL_INFO]',
  REMOVE_FILE: '[SAGAS][FILES][REMOVE_FILE]',
};
export const FILES_TREE = {
  GET_ALL_PROJECTS: '[SAGAS][FILES_TREE][GET_ALL_PROJECTS]',
  GET_PROJECT: '[SAGAS][FILES_TREE][GET_PROJECT]',
  GET_OBJECTS: '[SAGAS][FILES_TREE][GET_OBJECTS]',
  GET_EQUIPMETS: '[SAGAS][FILES_TREE][GET_EQUIPMETS]',
};
