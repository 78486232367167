import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './loadScreen.module.scss';

const LoadScreen = () => (
  <div className={styles.fullLoadScreen}>
    <CircularProgress
      style={{
        height: '80px',
        width: '80px',
      }}
    />
  </div>
);

export { LoadScreen };
