import React, { Suspense, lazy } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { LoadScreen } from '../components/loadScreen/loadScreen';

const MainPage = lazy(() => import('../../src/components/mainPage/mainPage'));

function RouteLogic() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Suspense fallback={<LoadScreen />}>
            <MainPage />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
}
export { RouteLogic };
