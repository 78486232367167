import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import {
  setFullProjetsList,
  setProjectsListLoadEnd,
  setFullActiveProjectsList,
} from '../actions/projects';
import {
  getProjectsList,
  getAllActiveProjects as getActiveProjectsList,
} from '../actions/sagas/projects';
import { ResponseGenerator, sagaArgumentsType } from './types/types';

export function* getAllProjects({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.projects.getAllProjects(
      $payload ?? {}
    );
    yield put(setFullProjetsList(responce?.data || []));
    yield put(setProjectsListLoadEnd());
  } catch (err) {
    yield put(setProjectsListLoadEnd());
    if (err?.message?.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения списка проектов', 'error')
      );
    }
  }
}
export function* getAllActiveProjects() {
  try {
    let responce: ResponseGenerator = yield API.projects.getAllProjects({
      status: 'ACTIVE',
      size: 2000,
    });
    yield put(setFullActiveProjectsList(responce?.data || []));
  } catch (err) {
    if (err?.message?.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения списка проектов', 'error')
      );
    }
  }
}
export function* addNewProject({ $payload }: sagaArgumentsType) {
  try {
    yield API.projects.addNewProjects($payload);
    yield put(notificationBySage('Проект создан успешно', 'success'));
    yield put(getProjectsList());
    yield put(getActiveProjectsList());
  } catch (err) {
    yield put(setProjectsListLoadEnd());
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка создания проекта', 'error'));
    }
  }
}

export function* updateProject({ $payload }: sagaArgumentsType) {
  try {
    yield API.projects.updateProjectById($payload.id, { ...$payload });
    yield put(notificationBySage('Проект обновлен успешно', 'success'));
    yield put(getProjectsList());
    yield put(getActiveProjectsList());
  } catch (err) {
    yield put(setProjectsListLoadEnd());
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка обновления проекта', 'error'));
    }
  }
}
