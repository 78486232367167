import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import {
  getObjectsList,
  getAllObjectsListDictionary as getAllObjectsListDictionaryAction,
} from '../actions/sagas/objects';
import {
  setFullObjectsList,
  setFullObjectsListDictionary,
} from '../actions/objects';
import { setObjectLoadEnd, setCurrentObjectData } from '../actions/object';
import { ResponseGenerator, sagaArgumentsType } from './types/types';

export function* getAllObjects({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.objects.getAllObjects({
      size: 99999,
      ...$payload,
    });
    yield put(setFullObjectsList(responce?.data));
    yield put(getAllObjectsListDictionaryAction());
    yield put(setObjectLoadEnd());
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      yield put(setObjectLoadEnd());
    } else {
      yield put(
        notificationBySage('Ошибка получения списка объектов', 'error')
      );
      yield put(setObjectLoadEnd());
    }
  }
}

export function* addNewObject({ $payload }: sagaArgumentsType) {
  try {
    yield API.objects.addNewObject($payload);
    yield put(notificationBySage('Объект создан успешно', 'success'));
    yield put(getObjectsList());
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      yield put(setObjectLoadEnd());
    } else {
      yield put(notificationBySage('Ошибка создания объекта', 'error'));
      yield put(setObjectLoadEnd());
    }
  }
}

export function* getObjectInfoById({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.objects.getCurrentObject(
      $payload
    );
    yield put(setCurrentObjectData(responce.data));
    yield put(setObjectLoadEnd());
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      yield put(setObjectLoadEnd());
    } else {
      yield put(
        notificationBySage('Ошибка получения данных о объекте', 'error')
      );
      yield put(setObjectLoadEnd());
    }
  }
}

export function* getAllObjectsListDictionary({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.objects.getAllObjects({
      ...$payload,
      size: 99999,
    });
    yield put(setFullObjectsListDictionary(responce?.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения списка объектов', 'error')
      );
    }
  }
}

export function* deleteObjectSaga({ $payload }: sagaArgumentsType) {
  try {
    yield API.objects.deleteObject($payload);
    yield put(getObjectsList());
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка удаления объекта', 'error'));
    }
  }
}

export function* updateObject({ $payload }: sagaArgumentsType) {
  try {
    yield API.objects.updateObject($payload);
    yield put(notificationBySage('Объект обновлен успешно', 'success'));
    yield put(getObjectsList());
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
      yield put(setObjectLoadEnd());
    } else {
      yield put(notificationBySage('Ошибка обновления объекта', 'error'));
      yield put(setObjectLoadEnd());
    }
  }
}
