import { CATALOGS } from '../../sagas/types';
import { createAction } from '../common';

export const getDocumentsCategoriesList = createAction(
  CATALOGS.GET_FULL_DOCUMENTS_CATEGORIES_LIST
);
export const addDocumentsCategoriesList = createAction(
  CATALOGS.ADD_DOCUMENT_CATEGORIES_LIST
);
export const postAddDocumentCategorie = createAction(
  CATALOGS.ADD_DOCUMENT_CATEGORIE
);
export const removeRemoveDocumentCategorie = createAction(
  CATALOGS.REMOVE_DOCUMENT_CATEGORIE
);
export const putEditDocumentCategorie = createAction(
  CATALOGS.EDIT_DOCUMENT_CATEGORIE
);

export const getApparatureTypesList = createAction(
  CATALOGS.GET_FULL_APPARATURE_TYPES_LIST
);
export const getApparatureTypesListForSelect = createAction(
  CATALOGS.GET_ALL_APPARATURE_FOR_SELECT
);
export const postAddApparatureCategories = createAction(
  CATALOGS.ADD_APPARATURE_TYPE
);
export const deleteApparatureType = createAction(
  CATALOGS.REMOVE_APPARATURE_TYPE
);
export const putEditApparatureType = createAction(
  CATALOGS.EDIT_APPARATURE_TYPE
);

export const getApparatureSubTypesList = createAction(
  CATALOGS.GET_FULL_APPARATURE_SUBTYPES_LIST
);
export const postAddApparatureSubCategories = createAction(
  CATALOGS.ADD_APPARATURE_SUBTYPE
);
export const deleteApparatureSubType = createAction(
  CATALOGS.REMOVE_APPARATURE_SUBTYPE
);
export const putEditApparatureSubType = createAction(
  CATALOGS.EDIT_APPARATURE_SUBTYPE
);

export const getAllTOStatuses = createAction(CATALOGS.GET_TO_STATUS_LIST);
export const putEditApparatureTODescription = createAction(
  CATALOGS.EDIT_TO_STATUS
);

export const getAllWorkStadies = createAction(CATALOGS.GET_WORK_STADIES_LIST);
export const putEditWorkStadies = createAction(CATALOGS.EDIT_WORK_STADIE);
export const postADDWorkStadies = createAction(CATALOGS.ADD_WORK_STADIE);
export const deleteWorkStadies = createAction(CATALOGS.REMOVE);
