import { all, takeLatest } from 'redux-saga/effects';
import {
  AUTHORIZATION_TYPES,
  USER_CONTROLLER_TYPES,
  OBJECT_CONTROLLER_TYPES,
  PROJECTS_CONTROLLER_TYPES,
  CATALOGS,
  APPARATURE,
  FILES,
  FILES_TREE,
} from './types';

import {
  authorizeUser,
  getUserInfoById,
  getUserInfo,
  logoutUser,
} from './authorizationSagas';
import {
  getAllUsers,
  removeUserById,
  updateUserInfoById,
  addNewUser,
  blockUserById,
  unBlockUserById,
} from './usersSagas';
import {
  getAllObjects,
  addNewObject,
  getObjectInfoById,
  getAllObjectsListDictionary,
  deleteObjectSaga,
  updateObject,
} from './objectsSagas';
import {
  getAllProjects,
  addNewProject,
  updateProject,
  getAllActiveProjects,
} from './projectsSagas';
import {
  addNewApparature,
  getAllApparatureSaga,
  updateApparature,
  patchRemoveApparature,
} from './apparature';
import {
  getAllDocumentsCategory,
  getDocumentsCategoryForSelect,
  addNewDocument,
  removeDocumentCategory,
  editDocumentCategory,
  getAllApparatureTypes,
  getAllApparatureTypesForSelect,
  addNewApparatureType,
  removeApparatureCategorie,
  editApparatureType,
  getAllApparatureSubTypesTypes,
  addNewApparatureSubType,
  removeApparatureSubCategorie,
  editApparatureSubType,
  getTOStatuses,
  editTODescription,
  getAllWorkStadiesSaga,
  addNewWorkStadies,
  editWorkStadies,
  removeWorkStadies,
} from './catalogsSagas';
import {
  uploadFile,
  getAllFiles,
  getDocumentInfo,
  removeDocument,
} from './files';
import {
  getProjectsForTree,
  getCurrentProjectForTree,
  getCurrentObjectForTree,
  getCurrentEquipmentForTree,
} from './filesTree';

function* rootSaga() {
  yield all([
    // @ts-ignore
    takeLatest(AUTHORIZATION_TYPES.ATHORIZATION, authorizeUser),
    takeLatest(AUTHORIZATION_TYPES.LOGOUT, logoutUser),
    takeLatest(
      AUTHORIZATION_TYPES.GET_CURRENT_USER_INFO_BY_ID,
      getUserInfoById
    ),
    takeLatest(AUTHORIZATION_TYPES.GET_CURRENT_USER_INFO, getUserInfo),
    takeLatest(USER_CONTROLLER_TYPES.GET_FULL_USERS_LIST, getAllUsers),
    takeLatest(USER_CONTROLLER_TYPES.REMOVE_USER_WITH_ID, removeUserById),
    takeLatest(USER_CONTROLLER_TYPES.UPDATE_USER_WITH_ID, updateUserInfoById),
    takeLatest(USER_CONTROLLER_TYPES.ADD_USER, addNewUser),
    takeLatest(USER_CONTROLLER_TYPES.BLOCK_USER, blockUserById),
    takeLatest(USER_CONTROLLER_TYPES.UN_BLOCK_USER, unBlockUserById),
    takeLatest(OBJECT_CONTROLLER_TYPES.GET_FULL_OBJECTS_LIST, getAllObjects),
    takeLatest(OBJECT_CONTROLLER_TYPES.DELETE_OBJECT, deleteObjectSaga),
    takeLatest(
      OBJECT_CONTROLLER_TYPES.GET_FULL_OBJECTS_LIST_DICTIONARY,
      getAllObjectsListDictionary
    ),
    takeLatest(OBJECT_CONTROLLER_TYPES.ADD_NEW_OBJECT, addNewObject),
    takeLatest(OBJECT_CONTROLLER_TYPES.GET_CURRENT_OBJECT, getObjectInfoById),
    takeLatest(OBJECT_CONTROLLER_TYPES.UPDATE_OBJECT, updateObject),

    takeLatest(
      PROJECTS_CONTROLLER_TYPES.GET_FULL_PROJECTS_LIST,
      getAllProjects
    ),
    takeLatest(PROJECTS_CONTROLLER_TYPES.ADD_NEW_PROJECT, addNewProject),
    takeLatest(PROJECTS_CONTROLLER_TYPES.UPDATE_PROJECT, updateProject),
    takeLatest(
      PROJECTS_CONTROLLER_TYPES.GET_FULL_ACTIVE_PROJECTS_LIST,
      getAllActiveProjects
    ),

    takeLatest(
      CATALOGS.GET_FULL_DOCUMENTS_CATEGORIES_LIST,
      getAllDocumentsCategory
    ),
    takeLatest(
      CATALOGS.ADD_DOCUMENT_CATEGORIES_LIST,
      getDocumentsCategoryForSelect
    ),
    takeLatest(CATALOGS.ADD_DOCUMENT_CATEGORIE, addNewDocument),
    takeLatest(CATALOGS.REMOVE_DOCUMENT_CATEGORIE, removeDocumentCategory),
    takeLatest(CATALOGS.EDIT_DOCUMENT_CATEGORIE, editDocumentCategory),
    takeLatest(CATALOGS.GET_FULL_APPARATURE_TYPES_LIST, getAllApparatureTypes),
    takeLatest(CATALOGS.ADD_APPARATURE_TYPE, addNewApparatureType),
    takeLatest(CATALOGS.REMOVE_APPARATURE_TYPE, removeApparatureCategorie),
    takeLatest(CATALOGS.EDIT_APPARATURE_TYPE, editApparatureType),
    takeLatest(CATALOGS.GET_WORK_STADIES_LIST, getAllWorkStadiesSaga),
    takeLatest(CATALOGS.ADD_WORK_STADIE, addNewWorkStadies),
    takeLatest(CATALOGS.EDIT_WORK_STADIE, editWorkStadies),
    takeLatest(CATALOGS.REMOVE, removeWorkStadies),
    takeLatest(
      CATALOGS.GET_FULL_APPARATURE_SUBTYPES_LIST,
      getAllApparatureSubTypesTypes
    ),
    takeLatest(CATALOGS.ADD_APPARATURE_SUBTYPE, addNewApparatureSubType),
    takeLatest(
      CATALOGS.REMOVE_APPARATURE_SUBTYPE,
      removeApparatureSubCategorie
    ),
    takeLatest(CATALOGS.EDIT_APPARATURE_SUBTYPE, editApparatureSubType),
    takeLatest(CATALOGS.GET_TO_STATUS_LIST, getTOStatuses),
    takeLatest(CATALOGS.EDIT_TO_STATUS, editTODescription),

    takeLatest(APPARATURE.ADD_NEW_APPARATURE, addNewApparature),
    takeLatest(APPARATURE.GET_ALL_APPARATURE, getAllApparatureSaga),
    takeLatest(
      CATALOGS.GET_ALL_APPARATURE_FOR_SELECT,
      getAllApparatureTypesForSelect
    ),
    takeLatest(APPARATURE.UPDATE_APPARATURE, updateApparature),
    takeLatest(APPARATURE.REMOVE_APPARATURE, patchRemoveApparature),

    takeLatest(FILES.UPLOAD_FILE, uploadFile),
    takeLatest(FILES.GET_FILES, getAllFiles),
    takeLatest(FILES.GET_FILE_FULL_INFO, getDocumentInfo),
    takeLatest(FILES.REMOVE_FILE, removeDocument),

    takeLatest(FILES_TREE.GET_ALL_PROJECTS, getProjectsForTree),
    takeLatest(FILES_TREE.GET_PROJECT, getCurrentProjectForTree),
    takeLatest(FILES_TREE.GET_OBJECTS, getCurrentObjectForTree),
    takeLatest(FILES_TREE.GET_EQUIPMETS, getCurrentEquipmentForTree),
  ]);
}

export default rootSaga;
