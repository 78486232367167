import React from 'react';
import { Provider } from 'react-redux';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import { SnackbarProvider } from 'notistack';
import { store } from './store';
import { AuthorizationModule } from './components/authorizationModule/authorizationModule';
import { RouteLogic } from './routes/router';
import { Notifier } from './helpers/notificator/notificator';
import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd.css';

function App() {
  const useStyles = makeStyles(() => ({
    top: {
      top: '90px',
    },
  }));
  const theme = createMuiTheme(
    {
      palette: {
        primary: { main: '#28a745' },
        divider: 'rgb(0, 156, 36)',
        action: {
          disabledBackground: '28a745',
        },
        tonalOffset: 0.1,
      },
    },
    ruRU
  );
  const classes = useStyles();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          preventDuplicate
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ root: classes.top }}
        >
          <Notifier />
          <AuthorizationModule>
            <RouteLogic />
          </AuthorizationModule>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
