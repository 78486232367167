import { FILES_TREE } from '../reducers/types';
import { createAction } from './common';

export const setAllProjectsForTree = createAction(FILES_TREE.SET_ALL_PROJECTS);
export const setCurrentProjectForTree = createAction(FILES_TREE.SET_PROJECT);
export const setObjectsForTree = createAction(FILES_TREE.SET_OBJECTS);
export const setEqupmentsForTree = createAction(FILES_TREE.SET_EQUIPMETS);
export const deleteFileFromTree = createAction(
  FILES_TREE.DELETE_FILE_FROM_TREE
);
