import { OBJECT_EDIT_TYPES } from '../reducers/types';
import { createAction } from './common';

export const setObjectLoadStart = createAction(
  OBJECT_EDIT_TYPES.CURRENT_OBJECT_GET_REQUEST_START
);
export const setObjectLoadEnd = createAction(
  OBJECT_EDIT_TYPES.CURRENT_OBJECT_GET_REQUEST_END
);
export const setCurrentObjectData = createAction(
  OBJECT_EDIT_TYPES.CURRENT_OBJECT_INFO_ADD
);
