import { TO_TYPES, GLOBAL_TYPES } from './types';
import { TOCategories } from './types/rootState';

const defaultState: TOCategories = {
  TOCategoriesList: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case TO_TYPES.SET_TO_TYPES_TYPES:
      return {
        ...state,
        TOCategoriesList: [...action?.$payload?.content],
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        TOCategoriesList: [],
      };
    default:
      return state;
  }
};
