import { APPARATURE_TYPE_TYPES, GLOBAL_TYPES } from './types';
import { ApparatureCategories } from './types/rootState';

const defaultState: ApparatureCategories = {
  apparatureCategoriesList: [],
  apparatureCategoriesForSelectList: [],
  apparatureCategoriesForSelectListTotalElements: 0,
  apparatureCategoriesListSize: 0,
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case APPARATURE_TYPE_TYPES.SET_APPARATURE_TYPE_TYPES:
      return {
        ...state,
        apparatureCategoriesList: [...action?.$payload?.content],
        apparatureCategoriesListSize: action?.$payload?.totalElements,
      };
    case APPARATURE_TYPE_TYPES.SET_APPARATURE_TYPE_FOR_SELECT: {
      return {
        ...state,
        apparatureCategoriesForSelectList: [
          ...state.apparatureCategoriesForSelectList,
          ...action?.$payload?.content,
        ],
        apparatureCategoriesForSelectListTotalElements:
          action?.$payload?.totalElements,
      };
    }
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        apparatureCategoriesList: [],
      };
    case GLOBAL_TYPES.CLEAR_ALL_TYPES_FOR_SELECT_INFO_FROM_REDUCER:
      return {
        ...state,
        apparatureCategoriesForSelectList: [],
        apparatureCategoriesForSelectListTotalElements: 0,
      };
    default:
      return state;
  }
};
