import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';
import sagas from './sagas';
// eslint-disable-next-line
declare global {
  // eslint-disable-next-line
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const enhancers = [];
const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

export const store = createStore(
  createRootReducer(),
  compose(applyMiddleware(sagaMiddleware), ...enhancers)
);

sagaMiddleware.run(sagas);
