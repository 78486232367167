import { USER_CONTROLLER_TYPES } from '../reducers/types';
import { createAction } from './common';

export const setFullUserList = createAction(
  USER_CONTROLLER_TYPES.SET_FULL_USERS_LIST
);
export const setUserListLoadStart = createAction(
  USER_CONTROLLER_TYPES.FULL_USERS_LIST_LOAD_START
);
export const setUserListLoadEnd = createAction(
  USER_CONTROLLER_TYPES.FULL_USERS_LIST_LOAD_END
);
