import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import {
  setAllProjectsForTree,
  setCurrentProjectForTree,
  setObjectsForTree,
  setEqupmentsForTree,
} from '../actions/filesTree';

import { ResponseGenerator, sagaArgumentsType } from './types/types';

export function* getProjectsForTree() {
  try {
    let responce: ResponseGenerator = yield API.filesTree.getAllProjects();
    yield put(setAllProjectsForTree(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения списка проектов', 'error')
      );
    }
  }
}

export function* getCurrentProjectForTree({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.filesTree.getProjectById(
      $payload.projectId
    );
    yield put(setCurrentProjectForTree(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения проекта', 'error'));
    }
  }
}

export function* getCurrentObjectForTree({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.filesTree.getObjectById(
      $payload.objectId
    );
    yield put(
      setObjectsForTree({
        data: responce.data,
        projectId: $payload.position.projectIndex,
        objectId: $payload.position.objectIndex,
      })
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения объекта', 'error'));
    }
  }
}

export function* getCurrentEquipmentForTree({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.filesTree.getEquipmentById(
      $payload.equipmentId
    );
    yield put(
      setEqupmentsForTree({
        data: responce.data,
        projectId: $payload.position.projectIndex,
        objectId: $payload.position.objectIndex,
        equipmentId: $payload.position.equipmentIndex,
      })
    );
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения оборудования', 'error'));
    }
  }
}
