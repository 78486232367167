import { NOTIFICATION_TYPES } from './types';

const defaultState = {
  notifications: [],
};

export default (
  state = defaultState,
  action: { type: any; key: any; notification: any; dismissAll: any }
) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
    case NOTIFICATION_TYPES.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification: any) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };
    case NOTIFICATION_TYPES.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification: any) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};
