import { put } from 'redux-saga/effects';
import API from '../helpers/restAPi/rest';
import { notificationBySage } from '../helpers/notificator/useNotificator';
import {
  addDocumentsCategories,
  setDocumentsCategories,
  setApparatureTypes,
  setApparatureSubTypes,
  setTOTypesList,
  setWorkStadiesList,
  setApparatureTypesForSelect,
} from '../actions/catalogs';
import {
  getDocumentsCategoriesList,
  getApparatureTypesList,
  getApparatureSubTypesList,
  getAllTOStatuses,
  getAllWorkStadies,
} from '../actions/sagas/catalogs';

import { ResponseGenerator, sagaArgumentsType } from './types/types';

export function* getAllDocumentsCategory({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllDocumentsCategory(
      {
        ...$payload,
      }
    );
    yield put(setDocumentsCategories(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения категорий документов', 'error')
      );
    }
  }
}

export function* getDocumentsCategoryForSelect({
  $payload,
}: sagaArgumentsType) {
  const { callback, params } = $payload;
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllDocumentsCategory(
      params
    );
    yield put(addDocumentsCategories(responce.data));
    callback(false);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения категорий документов', 'error')
      );
    }
  }
}

export function* addNewDocument({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, name, description } = $payload;
  const postData = { name, description };
  try {
    yield API.catalogs.postAddDocumentCategory(postData);
    yield put(getDocumentsCategoriesList(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка добавления категории документов', 'error')
      );
    }
  }
}
export function* removeDocumentCategory({ $payload }: sagaArgumentsType) {
  const { currentParams, removeId } = $payload;
  try {
    yield API.catalogs.deleteDocumentCategory(removeId);
    yield put(
      notificationBySage(
        'Удаление категории документов прошло успешно',
        'success'
      )
    );
    yield put(getDocumentsCategoriesList(currentParams));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка удаления категории документов', 'error')
      );
    }
  }
}
export function* editDocumentCategory({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, description, id, name } = $payload;
  try {
    yield API.catalogs.editDocumentCategory(id, name, description);
    yield put(
      notificationBySage(
        'Обновление категории документов прошло успешно',
        'success'
      )
    );
    yield put(getDocumentsCategoriesList(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка обновления категории документов', 'error')
      );
    }
  }
}

export function* getAllApparatureTypes({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllApparatureTypes({
      ...$payload,
    });
    yield put(setApparatureTypes(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения типов оборудования', 'error')
      );
    }
  }
}

export function* getAllApparatureTypesForSelect({
  $payload,
}: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllApparatureTypes({
      size: $payload.size,
      page: $payload.page,
    });
    yield put(setApparatureTypesForSelect(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения типов оборудования', 'error')
      );
    }
  }
}

export function* addNewApparatureType({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, name, description } = $payload;
  const postData = { name, description };
  try {
    yield API.catalogs.postAddApparatureCategory(postData);
    yield put(getApparatureTypesList(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка добавления типа оборудования', 'error')
      );
    }
  }
}
export function* removeApparatureCategorie({ $payload }: sagaArgumentsType) {
  const { currentParams, removeId } = $payload;
  try {
    yield API.catalogs.deleteApparatureType(removeId);
    yield put(
      notificationBySage('Удаление удаления типа оборудования', 'success')
    );
    yield put(getApparatureTypesList(currentParams));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка удаления типа оборудования', 'error')
      );
    }
  }
}
export function* editApparatureType({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, description, id, name } = $payload;
  try {
    yield API.catalogs.editApparatureType(id, name, description);
    yield put(
      notificationBySage(
        'Обновление типа оборудования прошло успешно',
        'success'
      )
    );
    yield put(getApparatureTypesList(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка обновления типа оборудования', 'error')
      );
    }
  }
}

export function* getAllApparatureSubTypesTypes({
  $payload,
}: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllApparatureSubTypes(
      {
        ...$payload,
      }
    );
    yield put(setApparatureSubTypes(responce.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка получения подтипов оборудования', 'error')
      );
    }
  }
}

export function* addNewApparatureSubType({ $payload }: sagaArgumentsType) {
  const {
    currentParams,
    description,
    equipmentTypeId,
    name,
    setCurrentParams,
  } = $payload;
  const postData = { description, equipmentTypeId, name };
  try {
    yield API.catalogs.postAddApparatureSubCategory(postData);
    yield put(getApparatureSubTypesList(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка добавления подтипа оборудования', 'error')
      );
    }
  }
}
export function* removeApparatureSubCategorie({ $payload }: sagaArgumentsType) {
  const { currentParams, removeId } = $payload;
  try {
    yield API.catalogs.deleteApparatureSubType(removeId);
    yield put(notificationBySage('Удаление подтипа оборудования', 'success'));
    yield put(getApparatureSubTypesList(currentParams));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка удаления подтипа оборудования', 'error')
      );
    }
  }
}
export function* editApparatureSubType({ $payload }: sagaArgumentsType) {
  const {
    currentParams,
    description,
    equipmentTypeId,
    id,
    name,
    setCurrentParams,
  } = $payload;
  try {
    yield API.catalogs.editApparatureSubType(
      id,
      name,
      description,
      equipmentTypeId
    );
    yield put(
      notificationBySage(
        'Обновление подтипа оборудования прошло успешно',
        'success'
      )
    );
    yield put(getApparatureSubTypesList(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(
        notificationBySage('Ошибка обновления подтипа оборудования', 'error')
      );
    }
  }
}

export function* getTOStatuses() {
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllTOStatuses();
    yield put(setTOTypesList(responce?.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения типов ТО', 'error'));
    }
  }
}
export function* editTODescription({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, description, id } = $payload;
  try {
    yield API.catalogs.editTODescription(id, description);
    yield put(
      notificationBySage('Обновление описания ТО прошло успешно', 'success')
    );
    yield put(getAllTOStatuses(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка обновления описания ТО', 'error'));
    }
  }
}

export function* getAllWorkStadiesSaga({ $payload }: sagaArgumentsType) {
  try {
    let responce: ResponseGenerator = yield API.catalogs.getAllWorkstadies({
      ...$payload,
    });
    yield put(setWorkStadiesList(responce?.data));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка получения стадий работы', 'error'));
    }
  }
}
export function* addNewWorkStadies({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, description, name } = $payload;
  const postData = { description, name };
  try {
    yield API.catalogs.postAddWorkStadies(postData);
    yield put(getAllWorkStadies(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка добавления стадии работы', 'error'));
    }
  }
}
export function* editWorkStadies({ $payload }: sagaArgumentsType) {
  const { setCurrentParams, currentParams, description, id, name } = $payload;
  try {
    yield API.catalogs.putEditWorkStadies(id, name, description);
    yield put(
      notificationBySage('Обновление стадии работы прошло успешно', 'success')
    );
    yield put(getAllWorkStadies(currentParams));
    setCurrentParams(currentParams);
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка обновления стадии работы', 'error'));
    }
  }
}
export function* removeWorkStadies({ $payload }: sagaArgumentsType) {
  const { currentParams, removeId } = $payload;
  try {
    yield API.catalogs.deleteWorkStadies(removeId);
    yield put(notificationBySage('Успешное удаление стадии работы', 'success'));
    yield put(getAllWorkStadies(currentParams));
  } catch (err) {
    if (err.message.length) {
      yield put(notificationBySage(err.message, 'error'));
    } else {
      yield put(notificationBySage('Ошибка удаления стадии работы', 'error'));
    }
  }
}
