import { CURRENT_USER_TYPES, GLOBAL_TYPES } from './types';
import { UserState } from './types/rootState';

const defaultState: UserState = {
  login: null,
  isUserInfoNowLoading: false,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  middleName: null,
  role: null,
  status: null,
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case CURRENT_USER_TYPES.CHANGE_CURRENT_USER_INFO:
      return {
        ...state,
        email: action?.$payload?.email,
        firstName: action?.$payload?.firstName,
        id: action?.$payload?.id,
        lastName: action?.$payload?.lastName,
        middleName: action?.$payload?.middleName,
        role: action?.$payload?.role,
        status: action?.$payload?.status,
        login: action?.$payload?.login,
      };
    case CURRENT_USER_TYPES.LOAD_USER_INFO_START:
      return {
        ...state,
        isUserInfoNowLoading: true,
      };
    case CURRENT_USER_TYPES.LOAD_USER_INFO_END:
      return {
        ...state,
        isUserInfoNowLoading: false,
      };
    case GLOBAL_TYPES.CLEAR_ALL_INFO_FROM_REDUCER:
      return {
        login: null,
        isUserInfoNowLoading: false,
        email: null,
        firstName: null,
        id: null,
        lastName: null,
        middleName: null,
        role: null,
        status: null,
      };
    default:
      return state;
  }
};
