import { FILES_TREE } from './types';
import {
  FileTreeCurrentProject,
  FileTreeProjectsList,
} from './types/rootState';

const defaultState: FileTreeProjectsList = {
  fileTreeProjectsList: [
    {
      id: null,
      name: null,
      documents: [{ id: null, name: null }],
      objects: [{ id: null, name: null, equipments: [], documents: [] }],
    },
  ],
};

const getIdForDeleteProjectsDocuments = (
  buffer: Array<FileTreeCurrentProject>,
  action: { type: any; key: any; $payload: any }
) => {
  let currentProjectId: null | number = null;
  let currentDocumentId: null | number = null;
  currentProjectId = buffer?.findIndex(
    el => el.id === action.$payload.projectId
  );
  if (currentProjectId !== undefined && currentProjectId != null) {
    currentDocumentId = buffer[currentProjectId]?.documents?.findIndex(
      el => el.id === action.$payload.id
    );
  }
  return { currentProjectId, currentDocumentId };
};

const getIdForDeleteObjectsDocuments = (
  buffer: Array<FileTreeCurrentProject>,
  action: { type: any; key: any; $payload: any }
) => {
  let currentProjectId: null | number = null;
  let currentObjectId: null | number = null;
  let currentDocumentId: null | number = null;
  currentProjectId =
    buffer?.findIndex(el => el.id === action.$payload.projectId) ?? null;
  if (currentProjectId != null && currentProjectId !== undefined) {
    currentObjectId = buffer[currentProjectId]?.objects?.findIndex(
      el => el.id === action.$payload.objectId
    );
  }
  if (currentObjectId != null && currentObjectId !== undefined) {
    currentDocumentId = buffer[currentProjectId]?.objects[
      currentObjectId
    ]?.documents?.findIndex(el => el.id === action.$payload.id);
  }
  return {
    currentProjectId,
    currentObjectId,
    currentDocumentId,
  };
};

const getIdForDeleteEquiomentsDocuments = (
  buffer: Array<FileTreeCurrentProject>,
  action: { type: any; key: any; $payload: any }
) => {
  let currentProjectId: null | number = null;
  let currentObjectId: null | number = null;
  let currentDocumentId: null | number = null;
  let currentEquiomentId: null | number = null;
  currentProjectId = buffer?.findIndex(
    el => el.id === action.$payload.projectId
  );
  if (currentProjectId !== undefined && currentProjectId != null) {
    currentObjectId = buffer[currentProjectId]?.objects?.findIndex(
      el => el.id === action.$payload.objectId
    );
  }
  if (currentObjectId !== undefined && currentObjectId != null) {
    currentEquiomentId = buffer[currentProjectId]?.objects[
      currentObjectId
    ]?.equipments?.findIndex(el => el.id === action.$payload.equipmentId);
  }
  if (
    currentEquiomentId !== undefined &&
    currentEquiomentId != null &&
    currentObjectId != null
  ) {
    currentDocumentId = buffer[currentProjectId]?.objects[
      currentObjectId
    ]?.equipments[currentEquiomentId]?.documents?.findIndex(
      el => el.id === action.$payload.id
    );
  }
  return {
    currentProjectId,
    currentObjectId,
    currentDocumentId,
    currentEquiomentId,
  };
};

export default (
  state = defaultState,
  action: { type: any; key: any; $payload: any }
) => {
  switch (action.type) {
    case FILES_TREE.SET_ALL_PROJECTS:
      return {
        fileTreeProjectsList: [...action?.$payload],
      };
    case FILES_TREE.SET_PROJECT:
      return {
        ...state,
        fileTreeProjectsList: state.fileTreeProjectsList.map(el => {
          if (el.id === action.$payload.id) {
            return action.$payload;
          }
          return el;
        }),
      };
    case FILES_TREE.SET_OBJECTS: {
      const buffer = state.fileTreeProjectsList;
      buffer[action.$payload.projectId].objects[
        action.$payload.objectId
      ].documents = [...action.$payload.data.documents];
      buffer[action.$payload.projectId].objects[
        action.$payload.objectId
      ].equipments = [...action.$payload.data.equipments];
      return {
        ...state,
        fileTreeProjectsList: [...buffer],
      };
    }
    case FILES_TREE.SET_EQUIPMETS: {
      const buffer = state.fileTreeProjectsList;
      buffer[action.$payload.projectId].objects[
        action.$payload.objectId
      ].equipments[action.$payload.equipmentId].documents = [
        ...action.$payload.data.documents,
      ];
      return {
        ...state,
        fileTreeProjectsList: [...buffer],
      };
    }
    case FILES_TREE.DELETE_FILE_FROM_TREE: {
      const buffer = state.fileTreeProjectsList;
      if (
        action.$payload.projectId != null &&
        action.$payload.objectId == null
      ) {
        const {
          currentProjectId,
          currentDocumentId,
        } = getIdForDeleteProjectsDocuments(buffer, action);
        if (currentProjectId != null && currentDocumentId != null) {
          buffer[currentProjectId].documents.splice(currentDocumentId, 1);
        }
      }
      if (
        action.$payload.projectId &&
        action.$payload.objectId &&
        action.$payload.equipmentId == null
      ) {
        const {
          currentProjectId,
          currentObjectId,
          currentDocumentId,
        } = getIdForDeleteObjectsDocuments(buffer, action);
        if (
          currentProjectId != null &&
          currentObjectId != null &&
          currentDocumentId != null
        ) {
          buffer[currentProjectId].objects[currentObjectId].documents.splice(
            currentDocumentId,
            1
          );
        }
      }
      if (
        action.$payload.projectId &&
        action.$payload.objectId &&
        action.$payload.equipmentId
      ) {
        const {
          currentProjectId,
          currentObjectId,
          currentDocumentId,
          currentEquiomentId,
        } = getIdForDeleteEquiomentsDocuments(buffer, action);
        if (
          currentProjectId != null &&
          currentObjectId != null &&
          currentEquiomentId != null &&
          currentDocumentId != null
        ) {
          buffer[currentProjectId].objects[currentObjectId].equipments[
            currentEquiomentId
          ].documents.splice(currentDocumentId, 1);
        }
      }
      return {
        ...state,
        fileTreeProjectsList: [...buffer],
      };
    }
    default:
      return state;
  }
};
