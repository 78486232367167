import axios from 'axios';

export type Method =
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'head'
  | 'HEAD'
  | 'options'
  | 'OPTIONS'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'purge'
  | 'PURGE'
  | 'link'
  | 'LINK'
  | 'unlink'
  | 'UNLINK';

export type ResponseType =
  | 'arraybuffer'
  | 'blob'
  | 'document'
  | 'json'
  | 'text'
  | 'stream';

type optionsType = {
  method: Method;
  responseType: ResponseType | undefined;
  url: string;
  body: any;
  path: string;
  queryParams: object;
  headers: object;
};
const getQueryParams = (queryParams: any) => {
  let query = [];
  let queryKeys = Object.keys(queryParams);
  for (let i = 0; i < queryKeys.length; i += 1) {
    if (typeof queryParams[queryKeys[i]] === 'object') {
      query.push(
        ...queryParams[queryKeys[i]].map(
          (soloObjectParam: any) => `${queryKeys[i]}=${soloObjectParam}`
        )
      );
    } else {
      query.push(`${queryKeys[i]}=${queryParams[queryKeys[i]]}`);
    }
  }
  return query.join('&');
};
const resolve = (response: any) => {
  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 204:
      return response;
    // return null;
    default:
      // console.warn(`Unknown response status: ${response.status}\n`, response);
      return null;
  }
};
const reject = (error: any) => {
  switch (error.response.status) {
    case 400:
    case 401:
    case 402:
    case 403:
      throw new Error(error.response.data.errorDescription ?? undefined);
    case 404:
      throw new Error('Страница не найдена');
    case 413:
      throw new Error(error.response.data.errorDescription ?? undefined);
    case 500:
    case 501:
    case 502:
      throw new Error('Произошла ошибка на сервере, попробуйте позже!');
    default:
      throw new Error('Неизвестная ошибка!');
  }
};

const options = ({
  method,
  url,
  body,
  path,
  queryParams,
  headers,
  responseType,
}: optionsType) => {
  const tokenType = 'Bearer';
  const accessToken = localStorage.getItem('authToken');
  const urlPath = `${process.env.REACT_APP_BACKEND_API_ADDR}${url ?? ''}${
    queryParams && Object.keys(queryParams).length !== 0
      ? `?${getQueryParams(queryParams)}`
      : ''
  }`;
  return {
    method,
    url: urlPath,
    ...(responseType && { responseType }),
    headers: {
      ...(accessToken && { Authorization: `${tokenType} ${accessToken}` }),
      ...headers,
    },
    data: body ?? null,
  };
};
const addToken = (authToken: string) => {
  localStorage.setItem('authToken', authToken);
};
const request = ({ ...props }: optionsType) =>
  axios(options(props))
    .then(response => {
      if (response.request.getResponseHeader('Authorization')) {
        addToken(response?.request?.getResponseHeader('Authorization'));
      }
      return resolve(response);
    })
    .catch(error => {
      reject(error);
    });

const restAPI = () => {
  const restMethod = ({ method, ...opts }: any) => (args: any) =>
    request({
      method,
      ...args,
      ...opts,
    });
  const get = restMethod({ method: 'GET' });
  const post = restMethod({ method: 'POST' });
  const put = restMethod({ method: 'PUT' });
  const patch = restMethod({ method: 'PATCH' });
  const remove = restMethod({ method: 'DELETE' });

  const auth = (url: string) => ({
    login: (login: string, password: string) =>
      post({
        url,
        body: { login, password },
      }),
  });
  const logout = (url: string) => ({
    logout: () =>
      post({
        url,
      }),
  });
  const users = (url: string) => ({
    getById: (id: number) =>
      get({
        url: `${url}/${id}`,
      }),
    getCurrent: () =>
      get({
        url: `${url}/current`,
      }),
    getAllUsers: (params: any) =>
      get({
        url,
        queryParams: { ...params },
      }),
    removeUserWithId: (id: number) =>
      remove({
        url: `${url}/${id}`,
      }),
    blockUserWithId: (id: number) =>
      patch({
        url: `${url}/block/${id}`,
      }),
    unBlockUserWithId: (id: number) =>
      patch({
        url: `${url}/unblock/${id}`,
      }),
    updateUserById: (id: number, newUserData: any) =>
      put({
        url: `${url}/${id}`,
        body: { ...newUserData },
      }),
    addUser: (data: any) =>
      post({
        url,
        body: { ...data },
      }),
  });
  const objects = (url: string) => ({
    getAllObjects: (params: any) =>
      post({
        url: `${url}/get`,
        body: { ...params },
        queryParams: { size: params?.size ?? 99999 },
      }),
    addNewObject: (objectInfo: any) =>
      post({
        url,
        body: { ...objectInfo },
      }),
    updateObject: (objectInfo: any) =>
      put({
        url: `${url}/${objectInfo.id}`,
        body: { ...objectInfo },
      }),
    getCurrentObject: (id: number) =>
      get({
        url: `${url}/${id}`,
      }),
    deleteObject: (id: number) =>
      patch({
        url: `${url}/delete/${id}`,
      }),
  });
  const projects = (url: string) => ({
    getAllProjects: (params: any) =>
      get({
        url,
        queryParams: { ...params },
      }),
    addNewProjects: (projectInfo: any) =>
      post({
        url,
        body: { ...projectInfo },
      }),
    updateProjectById: (id: number, projectInfo: any) =>
      put({
        url: `${url}/${id}`,
        body: { ...projectInfo },
      }),
  });
  const TO = (url: string) => ({
    addNewTO: (TOInfo: any) =>
      post({
        url,
        body: { ...TOInfo },
      }),
    removeTO: (id: any) =>
      remove({
        url: `${url}/${id}`,
      }),
  });
  const apparature = (url: string) => ({
    addNewApparature: (projectInfo: any) =>
      post({
        url,
        body: { ...projectInfo },
      }),
    getAllApparature: (searchObject: any) =>
      get({
        url,
        queryParams: { ...searchObject },
      }),
    updateNewApparature: (projectInfo: any) =>
      put({
        url: `${url}/${projectInfo.id}`,
        body: { ...projectInfo },
      }),
    removeApparature: (id: number) =>
      patch({
        url: `${url}/delete/${id}`,
      }),
  });
  const files = (url: string) => ({
    uploadFile: (formData: any, params: any) =>
      post({
        url,
        headers: {
          'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        },
        queryParams: { ...params },
        body: formData,
      }),
    getFiles: (queryParams: any) =>
      get({
        url,
        queryParams,
      }),
    getSoloDocument: (id: number) =>
      get({
        url: `${url}/${id}`,
        headers: {
          accept: 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      }),
    removeDocument: (id: number) =>
      remove({
        url: `${url}/${id}`,
      }),
  });
  const filesTree = (url: string) => ({
    getAllProjects: () =>
      get({
        url: `${url}/projects`,
      }),
    getProjectById: (id: number) =>
      get({
        url: `${url}/projects/${id}`,
      }),
    getObjectById: (id: number) =>
      get({
        url: `${url}/objects/${id}`,
      }),
    getEquipmentById: (id: number) =>
      get({
        url: `${url}/equipments/${id}`,
      }),
  });
  const catalogs = (url: string) => ({
    getAllDocumentsCategory: (searchObject: any) =>
      get({
        url: `${url}/document_categories`,
        queryParams: { ...searchObject },
      }),
    postAddDocumentCategory: (addCategoryData: {
      description: string;
      name: string;
    }) =>
      post({
        url: `${url}/document_categories`,
        body: addCategoryData,
      }),
    deleteDocumentCategory: (id: number) =>
      remove({
        url: `${url}/document_categories/${id}`,
      }),
    editDocumentCategory: (id: number, name: string, description: string) =>
      put({
        url: `${url}/document_categories/${id}`,
        body: {
          name,
          description,
        },
      }),

    getAllApparatureTypes: (searchObject: any) =>
      get({
        url: `${url}/equipment_types`,
        queryParams: { ...searchObject },
      }),
    postAddApparatureCategory: (addCategoryData: {
      description: string;
      name: string;
    }) =>
      post({
        url: `${url}/equipment_types`,
        body: addCategoryData,
      }),
    deleteApparatureType: (id: number) =>
      remove({
        url: `${url}/equipment_types/${id}`,
      }),
    editApparatureType: (id: number, name: string, description: string) =>
      put({
        url: `${url}/equipment_types/${id}`,
        body: {
          name,
          description,
        },
      }),

    getAllApparatureSubTypes: (queryParams: any = {}) =>
      get({
        url: `${url}/equipment_subtypes`,
        queryParams,
      }),
    postAddApparatureSubCategory: (addSubCategoryData: {
      description: string;
      name: string;
      equipmentTypeId: number;
    }) =>
      post({
        url: `${url}/equipment_subtypes`,
        body: addSubCategoryData,
      }),
    deleteApparatureSubType: (id: number) =>
      remove({
        url: `${url}/equipment_subtypes/${id}`,
      }),
    editApparatureSubType: (
      id: number,
      name: string,
      description: string,
      equipmentTypeId: number
    ) =>
      put({
        url: `${url}/equipment_subtypes/${id}`,
        body: {
          name,
          description,
          equipmentTypeId,
        },
      }),

    getAllTOStatuses: () =>
      get({
        url: `${url}/maintenance_statuses`,
      }),
    editTODescription: (id: number, description: string) =>
      put({
        url: `${url}/maintenance_statuses/${id}`,
        body: {
          description,
        },
      }),

    getAllWorkstadies: (queryParams: any = {}) =>
      get({
        url: `${url}/work_stages`,
        queryParams,
      }),
    postAddWorkStadies: (addWorkStadieData: {
      description: string;
      name: string;
    }) =>
      post({
        url: `${url}/work_stages`,
        body: addWorkStadieData,
      }),
    putEditWorkStadies: (id: number, name: string, description: string) =>
      put({
        url: `${url}/work_stages/${id}`,
        body: {
          name,
          description,
        },
      }),
    deleteWorkStadies: (id: number) =>
      remove({
        url: `${url}/work_stages/${id}`,
      }),
  });

  return {
    auth: auth('api/public/auth/login'),
    logout: logout('api/auth/logout'),
    users: users('api/users'),
    objects: objects('api/objects'),
    projects: projects('api/projects'),
    catalogs: catalogs('api'),
    apparature: apparature('api/equipments'),
    TO: TO('api/maintenances'),
    files: files('api/files'),
    filesTree: filesTree('api/file_tree'),
  };
};

export default restAPI();
